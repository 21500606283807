import { Box, Checkbox, Chip, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './page.css'
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";


import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useCreateRecordMutation } from '../slices/recordsSlice'
import { useParticularPageURLQuery } from '../slices/pageSlice';
import Navbar from '../header/navbar';
import {useSelector,useDispatch} from 'react-redux';
import { setSiteC } from '../slices/siteAuthSlice';

export default function Page(props) {



const {id}=useParams()

const {data:page,isLoading:isLoadingP}=useParticularPageURLQuery(id)



const dispatch=useDispatch()
    const [data,setData]=useState({check1:true,check2:true})
    const navigate=useNavigate()

   const [addRecord,{isLoading,isError}]   =  useCreateRecordMutation()


useEffect(() => {

    if(page){
        dispatch(setSiteC(page.site))
    }
 
}, [isLoadingP])


const bookPressed=async()=>{

if(data.fname&&data.lname&&data.email&&data.phone){

    let data1={fname:data.fname,lname:data.lname,email:data.email,phone:data.phone,site:page.site._id,page:page._id}

const record= await addRecord(data1).unwrap()
if (record){

setData({
    ...data,submitted:true
})

navigate('/thank-you')



    toast.success(record.message)
}

else{
    toast.error(record.message)

}


}
else{
    toast.error('All fields are necessary')
}


}
  
    return (
       <div>
{isLoadingP?
    <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{marginTop:'25vh',color:'#48a3b8'}}  />
      </Box>

:!isLoadingP&&page?
<>
<Navbar img={page.header}/>

<Grid container>

<Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>{page.metaTitle}</title>
       
    <meta
          name="description"
          content={page.metaDescription}
        />

<meta name="keywords" content={page.metaKeywords} />

        {/* <!-- Google / Search Engine Tags --> */}
       
       
        <meta itemprop="name" content={page.metaTitle}
         />
        
    
   
    <meta
    itemprop="description"
    content={page.metaDescription}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content={`https://${page.site.name}/${page.urlName}`} />
  
        <meta property="og:type" content="website" />
      
        <meta property="og:title" content={page.metaTitle} />
        
    
    <meta
          property="og:description"
          content={page.metaDescription}
        />
        
      
      

      
       
      </Helmet>
<Grid item sm={12} xs={12}>
    <div  className='img_bg'  style={{background:`url(${page.background})`}}>
<Grid item sm={6} xs={8}>
    <Typography className='text_bg_img'>
    {page.heading}
    </Typography>
</Grid>
    </div>

    <Grid container alignItems={'center'} style={{padding:'3vh 1vw'}}>
        <Grid item sm={2} xs={6}>
        <TextField label="First name*"    value={data.fname} onChange={(e)=>setData({...data,fname:e.target.value})}  className='txt_input' variant="outlined" />
        </Grid>
        <Grid item sm={2} xs={6}>
        <TextField label="Last name*"  value={data.lname} onChange={(e)=>setData({...data,lname:e.target.value})}  className='txt_input' variant="outlined" />
        </Grid>
        <Grid item sm={3} xs={6}>
        <TextField label="Phonenumber*" type={'number'}  value={data.phone} onChange={(e)=>setData({...data,phone:e.target.value})}  className='txt_input' variant="outlined" />
        </Grid>
        <Grid item sm={3} xs={6}>
        <TextField label="Email*"   error={data&&data.email&&data.email.length>0&&!data.email.includes('@')?true:false} type={'email'}  value={data.email} onChange={(e)=>setData({...data,email:e.target.value})}  className='txt_input' variant="outlined" />
      { data&&data.email&&data.email.length>0&&!data.email.includes('@')?<span style={{color:'red',fontSize:'0.9rem',padding:'5px 0px'}}>Provide valid email</span>:null}
       
       
        </Grid>
        <Grid item sm={2} xs={12}>
<Chip  onClick={()=>bookPressed()}  disabled={!data.check1||!data.check2||data&&data.email&&data.email.length>0&&!data.email.includes('@')} label={ isLoading?'Booking': data&&data.submitted&&!isLoading?'Booked':`${page.button}`}  className='chip_scan' />
        </Grid>
    </Grid>

    <Grid container alignItems={'center'} style={{padding:'3vh 1vw'}}>
   
   <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%'}} >
    <Checkbox   style={{color:'#48a3b8'}} onChange={(e)=>setData({...data,check1:e.target.checked})}   checked={data.check1} />
    <div style={{fontWeight:'600'}}> 
    I agree to receive communication on Call, WhatsApp & Email.
    </div>
    </div>
 
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} >
    <Checkbox checked={data.check2} onChange={(e)=>setData({...data,check2:e.target.checked})}   style={{color:'#48a3b8'}}   />
    <div style={{fontWeight:'600'}}> 
    I agree to the <a   style={{color:'#48a3b8'}} href='/terms-and-conditions' >terms of use</a> and <a   style={{color:'#48a3b8'}} href='/privacy-policy' >privacy policy</a>.
    </div>
    </div>
    </Grid>
    <Grid container justifyContent={'center'} alignItems={'center'} style={{padding:'3vh 1vw 5vh 1vh'}}>
       
        {page.components.filter(val=>val.isActive).map((val,idx)=>
       <Grid item key={idx} sm={2} xs={6}>
       <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>

<div className='div_multi'>
<img   src={val.image} className='img_multi' />
</div>
<Typography className='head_multi'>
{val.title}

</Typography>
<Typography className='sub_head_multi'>
{val.description}
</Typography>

       </Grid>
   </Grid> )}
        </Grid>
</Grid>
</Grid>
</>

:

window.location.href='/'
}


    </div> 
  )
}
