import { ChevronLeftRounded, Close } from '@mui/icons-material'
import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, CircularProgress, Container, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Paper, Switch, TextField, Typography } from '@mui/material'
import {  ExpandMore } from '@mui/icons-material'

import { useEditPageMutation, useParticularPageQuery } from '../slices/pageSlice';
import { useUploadImageMutation } from '../slices/apiSlice'
import { toast } from 'react-toastify';
import Records from './records';

export default function Particular_Page(props) {
  const page1=props.page
  const site1=props.site
  const {data:page,isLoading,refetch}=useParticularPageQuery(page1._id)
  const [uploadImage,{isLoading:isLoadingI}]=useUploadImageMutation()
const [open,setOpen]=useState(false)
const [edit1,setEdit1]=useState(false)
const [editPage,{isLoading:isLoadingE}]=useEditPageMutation()

 
  
  const [active,setActive]=useState('')
  const [active1,setActive1]=useState('')

const[edit,setEdit]=useState('')
const [data,setData]=useState({})
const ImageUpload=async(val,type)=>{
    if(type==='header'){
setData({
    ...data,header:val
})

const formData= new FormData()
formData.append('image',val)

const imageP= await uploadImage(formData).unwrap()

if(imageP){
    setData({
        ...data,header:imageP.data
    })
}

    }

   else if(type==='component'){
        setEdit({
            ...edit,image:val
        })
        
        const formData= new FormData()
        formData.append('image',val)
        
        const imageP= await uploadImage(formData).unwrap()
        
        if(imageP){
            setEdit({
                ...edit,image:imageP.data
            })
        }
        
            }
    else{
        setData({
            ...data,background:val
        })
        
        const formData= new FormData()
        formData.append('image',val)
        
        const imageP= await uploadImage(formData).unwrap()
        
        if(imageP){
            setData({
                ...data,background:imageP.data
            })
        }
    }

   
}

const EditComp=(val,idx)=>{
    setEdit1(true)
    setActive(idx)
    setEdit(val)
    setOpen(true)
    
    
    }
  
    const editTemplatePressed=async(val)=>{
        const editP=await editPage({
            id:page._id,
           background:data.background?data.background:page.background, 
           header:data.header?data.header:page.header, 
           heading:data.heading?data.heading:page.heading, 
           button:data.button?data.button:page.button, 
           urlName:data.urlName?data.urlName:page.urlName, 

        isActive:(data.isActive===false||data.isActive===true)?data.isActive:page.isActive,
        
           components:val?val:page.components
        
        
        }).unwrap()
        
        toast.success(editP.message)
        
        
        refetch()
    
        setData({})
        setEdit({})
        setOpen(false)
        
        
        
        
        }



        const saveClickedC=()=>{
if(edit1)

          { 
            
            const comp=page.components.slice()
            comp[active]=edit
            editTemplatePressed(comp)}
            else{
                if(page.components&&page.components[0])

{
    editTemplatePressed([...page.components,edit])
} 

else{

    editTemplatePressed([edit])

}
            }
        }


        const handleClose=()=>{
            setActive('')
        
            setEdit({})
        // setEdit({...edit,image:'',title:'',isActive:''})
        setOpen(false)
        } 

     const    ADD_Component =()=>{
        setOpen(true)
     }
const changeFxn=()=>{
if(!active1){
    props.changeF()
}
else{
setActive1('')
}

}

    return (
<div style={{width:'100%'}}>

 
{ 
 
 isLoading?
    <Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{marginTop:'25vh',color:'#48a3b8'}}  />
      </Box>
   : 
 
   
   


(


   <Grid container >
   <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{edit1? `Edit Component`:'Add new component'}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>
<Grid container>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>ImageUpload(e.target.files[0],'component')}  name='file1'/>
           { edit.image?<img  src={edit.image&&edit.image.name?URL.createObjectURL(edit.image):edit.image}  className='img_comp' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'var(--blueColor)',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {edit.image?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'var(--blueColor)'}}>Upload Component Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                {edit1?<FormGroup style={{float:'right'}}>
      <FormControlLabel style={{fontWeight:'600'}} control={<Switch onChange={(e)=>setEdit({...edit,isActive:e.target.checked})}  checked={edit.isActive?true:false}color='warning'  style={{color:'#CC0000' }} />} label="Is Active" />
      </FormGroup>:null}
                <TextField value={edit.title} label='Title*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setEdit({...edit,title:e.target.value})}
        focused={true} placeholder='Enter component title' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={edit.description}  style={{width:'100%'}}  variant={'outlined'} 
      focused={true}  onChange={(e)=>setEdit({...edit,description:e.target.value})}
         label='Description*' placeholder='Enter component decription' />
        
                </Grid> 
     
                </Grid> 
         
            </Grid>

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip  disabled={isLoadingI||isLoadingE} onClick={()=>saveClickedC()}   label={isLoadingE?'Saving':'Save Template'}  className='chip_new'/>
}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
<Grid container sm={12} xs={12}>
    <Grid item sm={2} xs={2}>
    <ChevronLeftRounded  onClick={()=>changeFxn()} style={{cursor:'pointer',color:'#48a3b8',fontSize:36,paddingLeft:10}} />
    </Grid>
    <Grid item sm={8} xs={8}>
    {!active1?<Typography style={{fontSize:20,fontWeight:'700'}}>
            {site1.name} / Pages /<span style={{color:'#48a3b8'}}> {page1.name}</span> 
        </Typography>:<Typography style={{fontSize:20,fontWeight:'700'}}>
            {site1.name} / Pages / {page1.name} /<span style={{color:'#48a3b8'}}> {active1}</span> 
        </Typography>}
    </Grid>
    
 
            </Grid>


            {
            !active1?(<Grid  style={{padding:'2vh 0px'}}textAlign={'center'} container sm={12}  xs={12}>

         
            <Grid  textAlign={' -webkit-center'} item sm={3} xs={12}>
  <Paper  onClick={()=>setActive1('Edit Template')} className='ppr_cat'>
  <Typography className='txt_cat'>
  {'Edit Template'}
  </Typography>
  
          </Paper>
          </Grid>
          <Grid  textAlign={' -webkit-center'} item sm={3} xs={12}>
  <Paper  onClick={()=>setActive1('Records')} className='ppr_cat'>
  <Typography className='txt_cat'>
  {'Records'}
  </Typography>
  
          </Paper>
          </Grid>
</Grid>):


active1==='Edit Template'?
<Container>
<Grid textAlign={'center'} item sm={12} style={{padding:'2vh 0px'}} xs={12}>

<Accordion>
<AccordionSummary
expandIcon={<ExpandMore />}
aria-controls="panel1-content"
id="panel1-header"

style={{fontWeight:'bolder'}}
>
Top Section
</AccordionSummary>
<AccordionDetails>
  
<Grid container sm={12} style={{padding:'2vh 0px'}} xs={12}>
<Grid item sm={12} xs={12}>
<FormGroup style={{float:'right'}}>
      <FormControlLabel style={{fontWeight:'600'}} control={<Switch onChange={(e)=>setData({...data,isActive:e.target.checked})}  checked={(data.isActive===false||data.isActive===true)?data.isActive:page.isActive}color='warning'  style={{color:'#CC0000' }} />} label="Is Active" />
      </FormGroup>
</Grid>
<Grid textAlign={'-webkit-center'} item sm={6} xs={12}>

<Paper   style={{height:100,width:100,borderRadius:'50%',borderWidth:2,borderColor:'#48a3b8'}}>
<img  style={{width:'100%',height:'100%',objectFit:'contain'}} src={data.header&&!data.header.name?data.header:data.header&&data.header.name?URL.createObjectURL(data.header):   page.header}   />
</Paper>

</Grid>
<Grid item sm={6} alignSelf={'center'} xs={12}>
    
<div style={{textAlign:'-webkit-center',marginTop:'2vh'}}>
   <label>
<input type='file' style={{display:'none'}}  onChange={(e)=>ImageUpload(e.target.files[0],'header')}  id='file1'/>
<div   for='file1' style={{width:'50%',textAlign:'center',background:'#48a3b8',padding:'10px 0px',borderRadius:20,cursor:'pointer'}}>
<h7 style={{width:'100%',fontSize:16,fontWeight:'600',color:'white'}}>{data.header?'Uploaded':'Change Logo'}</h7>
</div>
</label>
{/* {} */}
</div>



    </Grid>
    </Grid>


    <Grid container sm={12} style={{padding:'2vh 0px'}} xs={12}>

<Grid textAlign={'-webkit-center'} item sm={6} xs={12}>

<Paper   style={{height:200,width:300,borderWidth:2,borderColor:'#48a3b8'}}>
<img  style={{width:'100%',height:'100%',objectFit:'contain'}} src={data.background&&!data.background.name?data.background:data.background&&data.background.name?URL.createObjectURL(data.background):   page.background}   />
</Paper>

</Grid>
<Grid item sm={6} alignSelf={'center'} xs={12}>
    
<div style={{textAlign:'-webkit-center',marginTop:'2vh'}}>
   <label>
<input type='file' style={{display:'none'}}  onChange={(e)=>ImageUpload(e.target.files[0],'background')} id='file2'/>
<div   for='file2' style={{width:'50%',textAlign:'center',background:'#48a3b8',padding:'10px 0px',borderRadius:20,cursor:'pointer'}}>
<h7 style={{width:'100%',fontSize:16,fontWeight:'600',color:'white'}}>{data.background?'Uploaded':'Change Background Image'}</h7>
</div>
</label>
{/* {} */}
</div>



    </Grid>
    </Grid>

    <Grid container sm={12} style={{padding:'2vh 0px'}} xs={12}>

<Grid   style={{padding:'1vh 0px'}}  textAlign={'-webkit-center'} item sm={6} xs={12}>


<TextField  focused value={data.heading?data.heading:page.heading}  label='Main Heading'  onChange={(e)=>setData({...data,heading:e.target.value})}  multiline style={{width:'70%',textAlign:'-webkit-center'}}  />
</Grid>
<Grid style={{padding:'1vh 0px'}}  textAlign={'-webkit-center'} item sm={6} xs={12}>


<TextField  focused value={data.button?data.button:page.button}  label='Button text'  onChange={(e)=>setData({...data,button:e.target.value})}  multiline style={{width:'70%',textAlign:'-webkit-center'}}  />
</Grid>

<Grid style={{padding:'1vh 0px'}}  textAlign={'-webkit-center'} item sm={6} xs={12}>


<TextField  focused value={data.urlName?data.urlName:page.urlName}  label='Page URL'  onChange={(e)=>setData({...data,urlName:e.target.value})}  multiline style={{width:'70%',textAlign:'-webkit-center'}}  />
</Grid>
    </Grid>
    <Grid   textAlign={'center'} item sm={12} style={{padding:'2vh 0px'}} xs={12}>

<Chip  onClick={()=>editTemplatePressed()} disabled={isLoadingI||isLoadingE} label={isLoadingE?'Saving':'Save Template'}  className='chip_new'/>

       </Grid>
   </AccordionDetails>
</Accordion>
<Accordion>
<AccordionSummary
expandIcon={<ExpandMore />}
aria-controls="panel1-content"
id="panel1-header"

style={{fontWeight:'bolder'}}
>
Bottom Section
</AccordionSummary>
<AccordionDetails>
  
<Container style={{padding:'2vh 0px'}} >

<Grid textAlign={'end'} item sm={12} xs={12}>
<Chip  disabled={isLoadingI||isLoadingE} onClick={()=>ADD_Component()}   label={'Add New Component'}  className='chip_new'/>


</Grid>
{page.components.map((val,idx)=>
<Grid container style={{padding:'2vh 0px',marginBottom:'2vh',borderBottom:'1px solid black'}} key={idx}>



<Grid item sm={2} xs={3}>


<img  src={val.image}  className='img_comp' />

</Grid> 

<Grid container sm={9} xs={8}>
<Grid item sm={12} xs={12}>

<Typography  style={{fontSize:21,fontWeight:'600',color:'#ec7539'}}>
{val.title}

</Typography> 
</Grid> 
<Grid item sm={12} textAlign={'-webkit-center'} xs={12}>
<Typography style={{fontSize:19,fontWeight:'400'}}>
{val.description}


</Typography> 


{val.isActive?<div style={{padding:5,width:60,background:'green',color:'white',borderRadius:10,marginTop:5}}> &#x2022; Active</div>:<div style={{padding:5,width:70,background:'grey',color:'white',borderRadius:10,marginTop:5}}> &#x2022; Inactive</div>}
</Grid> 


</Grid> 
<Grid item sm={1} xs={1}>

<i onClick={()=>EditComp(val,idx)}  style={{color:'#ec7539',fontSize:20}} class="far fa-edit"></i>

</Grid> 
</Grid>  )}

    </Container>


  

   </AccordionDetails>
</Accordion>

</Grid>
</Container>: active1==='Records'?<Records page={page1} site={site1} />:null



}

                
    
         
        </Grid>
        
        
        
        )}
        </div>
  )
}
