import { Box, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import './dashboard.css'
import { useAllSitesQuery, useCreateSiteMutation } from '../slices/siteSlice'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import Site from './site';
import Navbar from '../header/navbar';
export default function Dashboard() {
  const [active,setActive]=useState('')
  const {data,isLoading,refetch}=useAllSitesQuery()
const[open,setOpen]=useState(false)
const [createSite,{isLoading:isLoadingC}]=useCreateSiteMutation()
const handleClose=()=>{
  setOpen(false)
  setSite({})
}
const [site,setSite]=useState({})
  const changeF=()=>{
    setActive('')
    refetch()
  }

const createSiteClicked=async()=>{

  if(site&&site.name&&site.header&&site.advertisement&&site.advertisement_url&&site.metaTitle&&site.metaDescription&&site.metaKeywords){


const formdata=new FormData()
formdata.append('name',site.name)
formdata.append('header',site.header)
formdata.append('advertisement',site.advertisement)
formdata.append('advertisement_url',site.advertisement_url)
formdata.append('metaTitle',site.metaTitle)
formdata.append('metaDescription',site.metaDescription)
formdata.append('metaKeywords',site.metaKeywords)


const site_new=await createSite(formdata).unwrap()
if(site_new){
  toast.info(site_new.message)
  handleClose()
refetch()

}


  }
  else{
    toast.error('All fields are necessary')
  }

}

  return (
<>

<Navbar />
    <Grid container justifyContent={'center'} style={{marginTop:'15vh',minHeight:'80vh'}}>
 
 <Dialog
        open={open}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        { <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'#48a3b8'}}>{`Create Site`}</DialogTitle>
}
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>
<Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setSite({...site,header:e.target.files[0]})}  name='file1'/>
           { site.header?<img  src={site.header&&site.header.name?URL.createObjectURL(site.header):site.header}  className='img_comp' />

          :<i  htmlFor='file1'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {site.header?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Header Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField value={site.name} label='Site Name*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,name:e.target.value})}
         placeholder='Enter site name' />
        
                </Grid> 
            
     
                </Grid> 
         
            </Grid>
            <Grid container style={{padding:'1vh 0px',alignItems:'center'}}>
               <Grid item sm={5} xs={3}>
              
              <div style={{textAlign:'center',paddingRight:'10px'}}>
               <label>
            <input type='file' style={{display:'none'}}  onChange={(e)=>setSite({...site,advertisement:e.target.files[0]})}  name='file2'/>
           { site.advertisement?<img  src={site.advertisement&&site.advertisement.name?URL.createObjectURL(site.advertisement):site.advertisement}  className='img_comp1' />

          :<i  htmlFor='file2'   style={{width:'100%',fontSize:35,textAlign:'center',padding:'2vh 0px',color:'#48a3b8',cursor:'pointer'}} class="fas fa-camera-retro"></i>}
          </label>
          {site.advertisement?null:<div style={{width:'100%',textAlign:'center'}}>
          <h7 style={{width:'100%',fontSize:20,fontWeight:'600',color:'#48a3b8'}}>Upload Advertisement Image*</h7>
          </div>}
          </div>
{/* <img  src={require('../images/JungleRun.jpg')}  className='img_game' /> */}

                </Grid> 

                <Grid container sm={7} xs={8}>
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
              
                <TextField value={site.advertisement_url} label='Advertisement url*'  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,advertisement_url:e.target.value})}
         placeholder='Enter advertisement url*' />
        
                </Grid> 
               
     
                </Grid> 
         
            </Grid>
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={site.metaTitle}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaTitle:e.target.value})}
         label='Meta Title*' placeholder='Enter meta title' />
        
                </Grid> 
            <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={site.metaDescription}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaDescription:e.target.value})}
         label='Meta Description*' placeholder='Enter meta description' />
        
                </Grid> 
                <Grid item style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <TextField value={site.metaKeywords}  style={{width:'100%'}}  variant={'outlined'} 
        onChange={(e)=>setSite({...site,metaKeywords:e.target.value})}
         label='Meta Keywords*' placeholder='Enter meta keywords' />
        
                </Grid> 

        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
        {
        <Chip  onClick={()=>createSiteClicked()}  disabled={isLoadingC}   label={isLoadingC?'Creating':'Create Site'}  className='chip_new'/>
}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>
 
 
 
 {isLoading?<Box  sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',width:'100%',justifyContent:'center' }}>
    <CircularProgress style={{color:'#48a3b8'}}  />
      </Box>:(!active? <>


  {data.map((val,idx)=>
  <Grid key={idx} textAlign={' -webkit-center'} item sm={3} xs={12}>
  <Paper  onClick={()=>setActive(val)} className='ppr_cat'>
  <Typography className='txt_cat'>
  {val.name}
  </Typography>
  
          </Paper>
          </Grid>)}
        <Grid textAlign={' -webkit-center'}  item sm={3} xs={12}>
        <Paper  onClick={()=>setOpen(true)} className='ppr_cat'>


<Typography className='txt_cat'>

 <span><AddCircleIcon style={{fontSize:30}} />
</span> 
{'Create new site'}
</Typography>

        </Paper>
        </Grid> 
        
        </> :<Site  changeF={changeF} site={active} />)}       </Grid>
        </> )
}
